.Button {
  padding: 0 10px;
  border-radius: var(--main-theme-border-radius);
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 48px;
  max-height: 100%;
  justify-content: center;
  font-size: 15px;
  transition: 0.3s;
  position: relative;
  text-align: center;
}

.Default {
    background: var(--main-theme);
    color: var(--main-theme-text);
    border: 1px solid var(--main-theme);
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: var(--main-theme-hover);
            border-color: var(--main-theme-hover);
        }
    }
    &:active{
        background-color: var(--main-theme-hover);
        border-color: var(--main-theme-hover);
    }
}

.Black {
    background-color: #333;
    color: #fff;
    border: 1px solid #333;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: #000;
            border-color: #000;
        }
    }
    &:active{
        background-color: #686868;
        border-color: #686868
    }
}

.White {
  background-color: #fff;
  color: var(--main-theme);;
  border: 1px solid #fff;
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: var(--main-theme-hover);
      border-color: var(--main-theme-hover);
      color: #fff;
    }
  }
  &:active{
    background-color: var(--main-theme-hover);
    border-color: var(--main-theme-hover);
  }
}

.BlackSelected {
    background-color: #fff;
    color: #333;
    border: 1px solid #333;
}

.Outline {
  background: #fff0;
  color: var(--main-theme-2);
  border: 1px solid var(--main-theme);
  .Loader {
    fill: var(--main-theme);
  }
  @media (hover: hover) and (pointer: fine) {
      &:hover {
          background-color: var(--main-theme-hover);
          border-color: var(--main-theme-hover);
          color: #fff;
          .Loader {
            fill: #fff;
          }
      }
  }
    &:active{
        background-color: var(--main-theme-hover);
        border-color: var(--main-theme-hover);
    }
}

.OutlineGray {
    color: var(--main-theme);
    border: 1px solid #D5D7E6;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            border-color: var(--main-theme-hover);
        }
    }
    &:active{
        border-color: var(--main-theme-hover);
    }
}

.OutlineWhite {
    background: #fff0;
    color: #fff;
    border: 1px solid #fff;
    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color: var(--main-theme-hover);
            border-color: var(--main-theme-hover);
        }
    }
    &:active{
        background-color: var(--main-theme-hover);
        border-color: var(--main-theme-hover);
    }
}

.Disabled {
    background-color: #D8D8D8;
    color: #fff;
    border-color: #D8D8D8;
    pointer-events: none;
}

.Loader {
    fill: #fff;
}
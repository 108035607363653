.Price {
  display: flex;
  gap: 8px;
  flex-direction: column;

  > button {
    margin-bottom: 5px;
    max-width: 300px;
  }
}

.BeforeValue {
  text-decoration-line: line-through;
  color: #93979b;
}

.Value {
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 700;
  }
}

.ValueBaltex {
  font-size: 24px;
  margin-bottom: 15px;

  span {
    float: left;
  }
}

.ValueOld {
  color: red;
  margin-bottom: 15px;
}

.Qty {
  font-size: 13px;
  display: flex;
  gap: 6px;
  margin-bottom: 15px;
  align-items: flex-end;
  width: 100%;
}

.InStock {
    color: #229317;
}

.OutOfStock {
    color: red;
}

.Link {
  color: var(--link-color);
  font-size: 15px;
  margin-bottom: 5px;
  cursor: pointer;
}

.Separator {
  margin-bottom: 6px;
}

.Buttons {
    display: flex;
    gap: 8px;
    flex-direction: column;
}
.Install {
  background-color: #f3f3f3;
  font-size: 14px;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  display: flex;
  grid-gap: 5px;
  gap: 5px;
  align-items: center;
  position: relative;
}

.InstallManager {
  font-size: 12px;
}

.Hint {
  color: var(--main-theme);
  border-radius: 50%;
  width: 15px;
  height: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  top: 0px;
  cursor: pointer;
  &:hover {
    .HintText {
      display: block;
    }
  }
  &Text {
    display: none;
    box-shadow: 0px 0px 5px 1px #c1c1c1;
    padding: 15px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: normal;
    color: #333;
    max-width: 300px;
    min-width: 200px;
    bottom: 30px;
    background-color: #fff;
    position: absolute;
    transition: 0.3s;
  }
  &Landing {
    width: 20px;
    height: 20px;
    right: 0;

  }
}

.Parners {
  display: flex;
  flex-direction: column;
  border: 1px solid;
  border-top: none;
  box-shadow: 0px 10px 20px rgb(170 170 170 / 26%);
  z-index: 5;
  position: relative;
  > a {
    padding: 5px 15px;
    font-size: 14px;
    color: #333;
  }
}

.BaltexBottom {
  display: flex;
  font-size: 13px;
  justify-content: space-between;

  .InStock {
    background-color: #D5EFCF;
    color: #2FB10F;
  }

  .OutOfStock {
    background-color: #FFE0E0;
    color: #FF6666;
  }

  .Qty {
    font-size: 13px;
    display: flex;
    gap: 6px;
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 100%;

    span {
      display: block;
      width: 100%;
      height: 100%;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.BaltexArt {
  font-size: 12px;
  margin-bottom: 10px;
  float: right;
  margin-left: 10px;
}

.ParnersText {
  font-size: 14px;
  color: #8A8C91;
  margin-top: 8px;
}

@media only screen and (max-width: 550px){

}

@media only screen and (max-width: 550px) {

    .Buttons {
        flex-direction: row;
        > div {
            flex: 1;
        }

    }
    .Qty {
        margin-bottom: -26px;
        margin-top: 5px;
        display: flex;
        justify-content: flex-end;
    }

    .Value {
        margin: 0;
        min-height: 30px;
    }
    .Separator {
        margin: -2px;
    }
    .Link {
        margin: 0;
    }
}
.Content {
  width: 800px;
  height: 600px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  iframe {
    margin-top: 30px;
    width: 100%;
  }

  .Buttons {
    font-size: 15px;
    margin: 20px 0;
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #D2D6DB;

    > div:first-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    > div:last-child {
      width: 300px;
    }

    a {
      display: flex;
      align-items: center;
      color: #4D5761;
      gap: 5px;
    }
  }

  .Social {
    font-size: 15px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    justify-content: space-between;
    color: #4D5761;

    span {
      min-width: 180px;
    }

    a {
      display: flex;
      color: #4D5761;
    }

    > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}

@media only screen and (max-width: 991px) {
  .Social {
    > div {
      width: inherit !important;
      display: grid!important;
      gap: 10px;
      grid-template-columns: 1fr 1fr 1fr ;
    }
  }
}


@media only screen and (max-width: 768px) {
  .Social {
    flex-direction: column;
  }
  .Buttons {
    flex-direction: column;
  }
}

@media only screen and (max-width: 500px) {
  .Buttons {
    font-size: 12px!important;
    margin-bottom: 10px!important;
    > div:first-child {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    > div:last-child {
      width: 100%;
    }

    a {
      display: flex;
      align-items: center;
      color: #4D5761;
      gap: 5px;
    }
  }
}

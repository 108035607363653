.Product {
  display: flex;
  flex-direction: column;

  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 8px 0 15px 0;
    position: relative;

    span {
      position: absolute;
      font-size: 14px;
      color: #000;
      font-weight: 700;
      left: 0;
      bottom: -32px;
    }
  }
}

.FilesAndBrands {
  display: flex;
  justify-content: space-between;
}

.Files {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.Photos {
    position: sticky;
    top: 110px;
    z-index: 10;
}

.MainInfo {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #D8D8D8;
  padding-bottom: 60px;
  margin-bottom: 20px;
}

.Props {
  margin: 8px 0;
  width: 100%;
  font-size: 13px;
  position: relative;

  &Bottom {
    display: flex;
    gap: 15px;
    justify-content: space-between;
  }
}

.Link {
  color: var(--link-color);
  cursor: pointer;
  margin-bottom: 22px;
}

.PropsTitle {
  font-weight: 600;
  color: #0F1323;
  margin-bottom: 20px;
}

.Buy {
  background: #FFFFFF;
  box-shadow: 0px 10px 30px rgba(0, 13, 37, 0.15);
  border-radius: 8px;
  padding: 28px 24px;
  min-width: 300px;
  margin-bottom: 20px;
}

.Delivery {
  border: 1px solid #D8D8D8;
  border-radius: 8px;
  padding: 20px 22px;
}

@media only screen and (max-width: 991px) {
  .MainInfo {
    flex-direction: column-reverse;
    gap: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .Buy {
      min-width: inherit;
  }
  .MainInfo {
    flex-direction: column;
    margin: 0 0 20px;
    border: none;
    padding: 0;
  }
  .Link {
    display: none;
  }
  .PropsBlock {
    margin-bottom: 20px;
  }
}
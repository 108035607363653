.ManagerData {
  background-color: #f8f8f8;
  padding: 5px 10px;
  font-size: 13px;
  border-radius: 10px;
  margin-bottom: 20px;

  > div {
    background-color: #efefef;
    display: block;
    border: 1px solid #000;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
  }
}
